import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
import { Device } from '@ionic-native/device/ngx';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private router: Router,
    private statusBar: StatusBar,
    private localNotifications: LocalNotifications,
    public oneSignal: OneSignal,
    private device: Device
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.localNotifications.on("click").subscribe((res) => {
        console.log('Clicked : ', res);
      });
      this.localNotifications.on("trigger").subscribe((res) => {
        console.log('Triggered: ', res);
      });
 
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // OneSignal
      this.oneSignal.startInit(
        "7b288086-9b31-46f3-b8f5-8fd27950acb8",
        "432985531637"
      );
      this.oneSignal.inFocusDisplaying(
        this.oneSignal.OSInFocusDisplayOption.InAppAlert
      );
      this.oneSignal.handleNotificationReceived().subscribe(
        (data) => this.onPushReceived(
        data.payload)
      );

      this.oneSignal.handleNotificationOpened().subscribe(
        (data) => this.onPushOpened(
        data.notification.payload
        )
      );

      this.oneSignal.endInit();
      // OneSignal
    });
  }

  onPushReceived(data) {
    //alert('Received : '+JSON.stringify(data));
  }

  onPushOpened(data) {
    //alert('Opened : '+JSON.stringify(data));
  }
}
